import { SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import { PlayerStats } from "../components/special/PlayerStats";
import { FilterStats } from "../DTOs/FilterStatsDTO";
import Tooltip from "rc-tooltip";
import 'rc-tooltip/assets/bootstrap.css';
import config from '../assets/config/config.json';
import { LoadingIcon } from "../components/special/LoadingIcon";
import Switch from "../components/basic/Switch";
import { statsShowFilter } from "../DTOs/StatsShowFilterDTO";
import { LogoText } from "../components/special/LogoText";
import LogoPng from "../assets/imgs/RivalScout_Logo.png";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { MultiStateCheckbox, MultiStateCheckboxChangeEvent } from 'primereact/multistatecheckbox';
import { BanHighlighter } from "../components/special/BanHighlighter";
import { useParams } from "react-router-dom";
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { CompCard } from "../components/special/CompCard";
import { CompFilterStats } from "../DTOs/CompDTOs/CompFilterStatsDTO";
import { ChampImg } from "../components/special/ChampImg";
import { SummonerSpellsDTO } from "../DTOs/MatchHistoryDTOs/SummonerSpellsDTO";
import { PerkDTO } from "../DTOs/MatchHistoryDTOs/PerkDTO";
import { ItemsDTO } from "../DTOs/MatchHistoryDTOs/ItemsDTO";
import { buildKey, buildKeyProps, CopyLinkOptions, GetCopyLink } from "../services/SearchLinkService";

export interface Region {
    name: string;
    code: string;
}

export const Tracker = () => {

    const urlparams = useParams();
    const [requestError, setRequestError] = useState("");

    //Search input
    const [summonernames, setSummonernames] = useState("");
    const [normalGames, setNormalGames] = useState(50);
    const [soloQGames, setSoloQGames] = useState(50);
    const [flexQGames, setFlexQGames] = useState(50);
    const [clashGames, setClashGames] = useState(50);
    const [tournamentGames, setTournamentGames] = useState(50);

    //Date Settings
    const today = Date.now() / 1000;
    const dayTime = 86400;
    const weekTime = 604800;
    const monthTime = 2629743;
    const [searchDate, setSearchDate] = useState(today - monthTime);
    const [dateValue, setDateValue] = useState(8);
    const dates: number[] = [
        (today - dayTime),
        (today - (dayTime * 2)),
        (today - (dayTime * 3)),
        (today - (dayTime * 4)),
        (today - (dayTime * 5)),
        (today - (dayTime * 6)),
        (today - weekTime),
        (today - (weekTime * 2)),
        (today - (weekTime * 3)),
        (today - monthTime),
        (today - (monthTime * 2)),
        (today - (monthTime * 3))
    ]
    const dateStrings: string[] = [
        "1 Day",
        "2 Days",
        "3 Days",
        "4 Days",
        "5 Days",
        "6 Days",
        "1 Week",
        "2 Weeks",
        "3 Weeks",
        "1 Month",
        "2 Months",
        "3 Months"
    ]

    //Region Settings

    const [selectedRegion, setSelectedRegion] = useState<Region>({ name: 'Europe West', code: 'euw1' });
    const regions: Region[] = [
        { name: 'Europe West', code: 'euw1' },
        { name: 'Europe Nordic & East', code: 'eun1' },
        { name: 'Turkey', code: 'tr1' },
        { name: 'Russia', code: 'ru' },
        { name: 'Korea', code: 'kr' },
        { name: 'Japan', code: 'jp1' },
        { name: 'North America', code: 'na1' },
        { name: 'Brazil', code: 'br1' },
        { name: 'LAN', code: 'la1' },
        { name: 'LAS', code: 'la2' },
        { name: 'Oceania', code: 'oc1' },
        { name: 'Philippines', code: 'ph2' },
        { name: 'Singapore', code: 'sg2' },
        { name: 'Thailand', code: 'th2' },
        { name: 'Taiwan', code: 'tw2' },
        { name: 'Vietnam', code: 'vn2' }

    ];


    //Filter Settings
    const [useGamesFilter, setUseGamesFilter] = useState(false);

    const showStatsFilterOptions = [
        { value: true, icon: 'pi pi-check' },
        { value: false, icon: 'pi pi-times' },
    ];

    //Filter Settings - Champ Stats
    const [filterSettings, setfilterSettings] = useState({
        games: 0, winrate: 0, kda: 0, gold: 0, cs: 0, visionScore: 0,
        normalQ: null, soloQ: null, flexQ: null, clash: null, tournament: null
    } as FilterStats)
    const filterSettingsOptions = [
        { value: true, icon: 'pi pi-check' },
        { value: false, icon: 'pi pi-times' },
    ];

    const [showStatsFilter, setShowStatsFilter] = useState({
        games: true, winrate: true, kda: true, kills: false, deaths: false, assists: false, gold: true, cs: true, visionScore: true
    } as statsShowFilter)

    //Filter Settings - Team-Comp analysis
    const [compFilterSettings, setCompFilterSettings] = useState({
        games: 2, compChamps: [], compSize: 2, winrate: 0, showNames: false, showDamage: true, showGames: true, showGameTime: true, showWinrate: true
    } as CompFilterStats)

    //Handle Comp filter Selector
    const HandleCompChampSelector = (name: string) => {
        let tempCompChampSelectorList: string[] = [];
        compFilterSettings.compChamps.forEach((ban) => {
            tempCompChampSelectorList.push(ban)
        })
        let inListIndex = -1;
        for (let i = 0; i < tempCompChampSelectorList.length; i++) {
            if (tempCompChampSelectorList[i] === name)
                inListIndex = i
        }

        if (inListIndex !== -1) {
            tempCompChampSelectorList.splice(inListIndex, 1)
        } else {
            tempCompChampSelectorList.push(name)
        }
        setCompFilterSettings({ ...compFilterSettings, compChamps: tempCompChampSelectorList })
    }

    // Search Post Settings
    const [keys, setKeys] = useState('');
    const [hotreloadCount, setHotreloadCount] = useState(0);
    const [endpoint, setEndpoint] = useState("track");

    //trigger Reaload on Serarchling
    const [searchlinkRelaod, setSearchlinkRelaod] = useState(false);

    //Default Res Data for player stats
    const [resData, setResData]: any = useState([]);
    //Res Data for TeamComp analysis
    const [resDataComp, setResDataComp]: any = useState([]);
    const [loading, setloading] = useState(false);

    //Res Data for Stats
    const [resDataStats, setResDataStats]: any = useState([]);
    const [loadingStats, setloadingStats] = useState(false);

    //Res Data for MatchHistory
    const [resDataMatchHistory, setResDataMatchHistory]: any = useState([]);
    const [loadingMatchHistory, setloadingMatchHistory] = useState(false);


    //Ban Highlight Settings
    const [banList, setBanList] = useState<string[]>([]);

    //Handle BanList
    const HandleBan = (name: string) => {
        let tempBanList: string[] = [];
        banList.forEach((ban) => {
            tempBanList.push(ban)
        })
        let inListIndex = -1;
        for (let i = 0; i < tempBanList.length; i++) {
            if (tempBanList[i] === name)
                inListIndex = i
        }

        if (inListIndex !== -1) {
            tempBanList.splice(inListIndex, 1)
        } else {
            tempBanList.push(name)
        }
        setBanList(tempBanList);
    }

    function limitNumberWithinRange(num: number, min: number, max: number) {
        const MIN = min;
        const MAX = max;
        return Math.min(Math.max(num, MIN), MAX)
    }

    function getStringValue(value: any): string {
        return value.toString();
    }

    const handleSearchDateRangeChange = (datenNum: number) => {
        setDateValue(datenNum);
        setSearchDate(dates[datenNum])
    }

    const keepRange = (value: number, min: number, max: number) => {
        if (value < min)
            value = max;
        else if (value > max)
            value = min;

        return value;
    }

    const setFilter = () => {

        const tempKey: string = buildKey({
            currentEndpoint: endpoint,
            useGamesFilter: useGamesFilter,
            summonernames: summonernames,
            selectedRegion: selectedRegion,
            normalGames: normalGames,
            soloQGames: soloQGames,
            flexQGames: flexQGames,
            clashGames: clashGames,
            tournamentGames: tournamentGames,
            searchDate: searchDate
        } as buildKeyProps);
        if (keys !== tempKey)
            setKeys(tempKey)
        else if (keys === tempKey)
            setHotreloadCount(hotreloadCount + 1);
    }

    const [copyLinkOptions, setCopyLinkOptions] =useState<CopyLinkOptions>({
        useGamesFilter: useGamesFilter,
        summonernames: summonernames,
        selectedRegion: selectedRegion,
        normalGames: normalGames,
        soloQGames: soloQGames,
        flexQGames: flexQGames,
        clashGames: clashGames,
        tournamentGames: tournamentGames,
        dateValue: dateValue,
    } as CopyLinkOptions)

    useEffect(()=>{
        setCopyLinkOptions({
            useGamesFilter: useGamesFilter,
            summonernames: summonernames,
            selectedRegion: selectedRegion,
            normalGames: normalGames,
            soloQGames: soloQGames,
            flexQGames: flexQGames,
            clashGames: clashGames,
            tournamentGames: tournamentGames,
            dateValue: dateValue,
        } as CopyLinkOptions)
    },[useGamesFilter, summonernames, selectedRegion, normalGames, soloQGames, flexQGames, clashGames, tournamentGames, dateValue])


    //Tab Control
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    useEffect(() => {
        tabChange(activeTabIndex);
    }, [activeTabIndex])

    const tabChange = (index: number) => {
        switch (index) {
            case 0:
                setEndpoint("track");
                break;
            case 1:
                setEndpoint("getTeamcomp");
                break;
        }
    }

    //use Effects

    useEffect(() => {
        if (filterSettings.games > 0) {
            setShowStatsFilter({ ...showStatsFilter, games: true })
        }
        if (filterSettings.winrate > 0) {
            setShowStatsFilter({ ...showStatsFilter, winrate: true })
        }
        if (filterSettings.kda > 0) {
            setShowStatsFilter({ ...showStatsFilter, kda: true })
        }
        if (filterSettings.gold > 0) {
            setShowStatsFilter({ ...showStatsFilter, gold: true })
        }
        if (filterSettings.cs > 0) {
            setShowStatsFilter({ ...showStatsFilter, cs: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSettings])


    const [version, setVersion] = useState("13.1.1");
    const [summonerJson, setSummonerJson] = useState<SummonerSpellsDTO>();
    const [runesJson, setRunesJson] = useState<PerkDTO[]>();
    const [itemJson, setItemJson] = useState<ItemsDTO>();
    useEffect(() => {
        axios({
            method: 'Get',
            url: config.riot_version_url
        }).then((res) => {
            setVersion(getStringValue(res.data[0]));
            axios({
                method: 'Get',
                url: config.riot_champ_img_base_url + getStringValue(res.data[0]) + "/data/en_US/summoner.json"
            }).then((res) => {
                setSummonerJson(res.data as SummonerSpellsDTO);
            })

            axios({
                method: 'Get',
                url: config.cdragon_perks
            }).then((res) => {
                setRunesJson(res.data as PerkDTO[]);
            })

            axios({
                method: 'Get',
                url: config.riot_champ_img_base_url + getStringValue(res.data[0]) + "/data/en_US/item.json"
            }).then((res) => {
                setItemJson(res.data as ItemsDTO);
            })
        })
    }, []);


    //TODO: Marker hier
    useEffect(() => {
        if (urlparams.id !== undefined) {

            const names = urlparams.id.split("-")[0].split("+");
            const times = urlparams.id.split("-")[1].split("+");
            const region = urlparams.id.split("-")[2];
            let i = 0;
            while (i < regions.length) {

                if (regions[i].code === region) {
                    setSelectedRegion(regions[i])
                    i = regions.length;
                }

                i++;
            }
            setSummonernames(names.toString());
            if (times.length === 5) {
                setUseGamesFilter(true);
                setNormalGames(keepRange(parseInt(times[0]), 0, 100));
                setSoloQGames(keepRange(parseInt(times[1]), 0, 100));
                setFlexQGames(keepRange(parseInt(times[2]), 0, 100));
                setClashGames(keepRange(parseInt(times[3]), 0, 100));
                setTournamentGames(keepRange(parseInt(times[4]), 0, 100));
                setSearchlinkRelaod(true);
            } else if (times.length === 1) {
                setUseGamesFilter(false);
                setDateValue(parseInt(times[0]))
                handleSearchDateRangeChange(parseInt(times[0]))
                setSearchlinkRelaod(true);
            } else {
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlparams.id])

    useEffect(() => {
        if (searchlinkRelaod) {
            setFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchlinkRelaod])

    useEffect(() => {
        if (keys !== '') {
            setloading(true);
            axios({
                method: 'Get',
                baseURL: config.api_url,
                url: keys,
                withCredentials: true
            }).then((res) => {
                switch (endpoint) {
                    case "track":
                        setResData(res.data);
                        setloadingStats(true)
                        setloadingMatchHistory(true)
                        break;
                    case "getTeamcomp":
                        setResDataComp(res.data);
                        break;
                }
                setRequestError("")
            }).catch((err: { response: { data: { detail: { toString: () => SetStateAction<string>; }; }; }; }) => {
                setloading(false);
                setRequestError(err.response.data.detail.toString())
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keys, hotreloadCount]);


    useEffect(() => {
        if (loadingStats) {
            axios({
                method: 'Get',
                baseURL: config.api_url,
                url: buildKey({
                    currentEndpoint: "getPlayerStats",
                    useGamesFilter: useGamesFilter,
                    summonernames: summonernames,
                    selectedRegion: selectedRegion,
                    normalGames: normalGames,
                    soloQGames: soloQGames,
                    flexQGames: flexQGames,
                    clashGames: clashGames,
                    tournamentGames: tournamentGames,
                    searchDate: searchDate
                } as buildKeyProps),
                withCredentials: true
            }).then((res) => {
                setResDataStats(res.data);
                setloadingStats(false)
            }).catch((err) => {
                setloading(false);
                setloadingStats(false);
                setRequestError(err.response.data.detail.toString())
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingStats]);

    useEffect(() => {
        if (loadingMatchHistory) {
            axios({
                method: 'Get',
                baseURL: config.api_url,
                url:  buildKey({
                    currentEndpoint: "getPlayerMatchHistory",
                    useGamesFilter: useGamesFilter,
                    summonernames: summonernames,
                    selectedRegion: selectedRegion,
                    normalGames: normalGames,
                    soloQGames: soloQGames,
                    flexQGames: flexQGames,
                    clashGames: clashGames,
                    tournamentGames: tournamentGames,
                    searchDate: searchDate
                } as buildKeyProps),
                withCredentials: true
            }).then((res) => {
                setResDataMatchHistory(res.data);
                setloadingMatchHistory(false)
            }).catch((err) => {
                setloading(false);
                setloadingMatchHistory(false);
                setRequestError(err.response.data.detail.toString())
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingMatchHistory]);

    useEffect(() => {
        setloading(false);
    }, [resData, resDataComp])

    return (
        <div className="tracker">
            <div className="tracker-filter">

                <span className="tracker-filter-input">
                    <Tooltip mouseEnterDelay={0.25} placement="top" overlay={<p>Multiple Summonernames must be seperated with , <br />Example:<br />Awsome Name,Another Name </p>} >
                        <label className="tracker-filter-input-label" htmlFor="summonername" >Summonernames:</label>
                    </Tooltip>
                    <textarea id="summonername" className="tracker-filter-input-text" value={summonernames} onChange={(e) => (setSummonernames(e.target.value))}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault()
                                setFilter()
                            }
                        }} />
                </span>
                <span className="tracker-filter-input tracker-filter-input-region">
                    <Dropdown value={selectedRegion} onChange={(e: DropdownChangeEvent) => setSelectedRegion(e.value)} options={regions} optionLabel="name" className="w-full md:w-14rem region-dropdown" />
                </span>
                <span className="tracker-filter-input">
                    <Tooltip mouseEnterDelay={0.25} placement="top" overlay={<p>Switch between match number search and date search</p>} >
                        <span className="tracker-filter-input-label"><p></p></span>
                    </Tooltip>
                    <Switch checked={useGamesFilter} onChange={() => { setUseGamesFilter(!useGamesFilter) }} />

                </span>
                {useGamesFilter &&
                    <span className="tracker-filter-input-games">
                        <span>
                            <Tooltip mouseEnterDelay={0.25} placement="top" overlay={<p>How much Draft-Games you like to track<br />more games = more loading time (max100)</p>} >
                                <label className="tracker-filter-input-label" htmlFor="normalgames" ><b>D</b>raft:</label>
                            </Tooltip>
                            <input id="normalgames" className="tracker-filter-input-games-number" value={normalGames} onChange={(e) => (setNormalGames(limitNumberWithinRange(parseInt(e.target.value) || 0, 0, 100)))} />
                        </span>
                        <span>
                            <Tooltip mouseEnterDelay={0.25} placement="top" overlay={<p>How much SoloQ-Games you like to track<br />more games = more loading time (max100)</p>} >
                                <label className="tracker-filter-input-label" htmlFor="soloQGames" ><b>S</b>olo:</label>
                            </Tooltip>
                            <input id="soloQGames" className="tracker-filter-input-games-number" value={soloQGames} onChange={(e) => (setSoloQGames(limitNumberWithinRange(parseInt(e.target.value) || 0, 0, 100)))} />
                        </span>
                        <span>
                            <Tooltip mouseEnterDelay={0.25} placement="top" overlay={<p>How much FlexQ-Games you like to track<br />more games = more loading time (max100)</p>} >
                                <label className="tracker-filter-input-label" htmlFor="flexQGames" ><b>F</b>lex:</label>
                            </Tooltip>
                            <input id="flexQGames" className="tracker-filter-input-games-number" value={flexQGames} onChange={(e) => (setFlexQGames(limitNumberWithinRange(parseInt(e.target.value) || 0, 0, 100)))} />
                        </span>
                        <span>
                            <Tooltip mouseEnterDelay={0.25} placement="top" overlay={<p>How much Clash-Games you like to track<br />more games = more loading time (max100)</p>} >
                                <label className="tracker-filter-input-label" htmlFor="clashGames" ><b>C</b>lash:</label>
                            </Tooltip>
                            <input id="clashGames" className="tracker-filter-input-games-number" value={clashGames} onChange={(e) => (setClashGames(limitNumberWithinRange(parseInt(e.target.value) || 0, 0, 100)))} />
                        </span>
                        <span>
                            <Tooltip mouseEnterDelay={0.25} placement="top" overlay={<p>How much Tournament-Games you like to track<br />more games = more loading time (max100)</p>} >
                                <label className="tracker-filter-input-label" htmlFor="tournamentgames" ><b>T</b>ournament:</label>
                            </Tooltip>
                            <input id="tournamentgames" className="tracker-filter-input-games-number" value={tournamentGames} onChange={(e) => (setTournamentGames(limitNumberWithinRange(parseInt(e.target.value) || 0, 0, 100)))} />
                        </span>
                    </span>}
                {!useGamesFilter &&
                    <span className="tracker-filter-input-games">
                        <span>
                            <Tooltip mouseEnterDelay={0.25} placement="top" overlay={<p>Searches all games from the selected date, but no more than the last 100 games.</p>} >
                                <label className="tracker-filter-input-label tracker-filter-input-label-slider" htmlFor="date" >All Games since <b>{dateStrings[dateValue]}</b></label>
                            </Tooltip>
                            <input id="filtergames" type="range" className="slider" step={1} min={0} max={11} value={dateValue} onChange={(e) => (handleSearchDateRangeChange(parseInt(e.target.value)))} />
                        </span>
                    </span>
                }
                <span className="tracker-filter-btn">
                    <button className="tracker-filter mouse-pointer" onClick={() => setFilter()}>Search</button>
                </span>
                <span className="tracker-filter-btn">
                    <button className="tracker-filter copybtn mouse-pointer" onClick={() => { navigator.clipboard.writeText(GetCopyLink(copyLinkOptions)) }}>Copy Search Link</button>
                </span>
            </div>
            <div className="tracker-content">

                <div className="tracker-content-filter">
                    {
                        //Champ Stats Filter
                    }
                    {endpoint === "track" &&
                        <Accordion multiple activeIndex={[0]}>
                            <AccordionTab header="Filter">
                                <span className="tracker-content-filter-element">
                                    <span className="tracker-content-filter-element-filter-slider">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>The minimum number of games a champ must have in a queue.</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtergames" >Games 	&#062;= <b>{filterSettings.games}</b></label>
                                        </Tooltip>
                                        <input id="filtergames" type="range" className="slider" min={0} max={30} value={filterSettings.games} onChange={(e) => (setfilterSettings({ ...filterSettings, games: parseInt(e.target.value) || 0 }))} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-slider">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>The minimum Winrate a champ must have in a queue.</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filterwinrate" >Winrate &#062;= <b>{filterSettings.winrate}</b></label>
                                        </Tooltip>
                                        <input id="filterwinrate" type="range" className="slider" min={0} max={100} value={filterSettings.winrate} onChange={(e) => (setfilterSettings({ ...filterSettings, winrate: parseInt(e.target.value) || 0 }))} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-slider">

                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>The minimum KDA a champ must have in a queue.</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filterkda" >K/D/A &#062;= <b>{filterSettings.kda}</b></label>
                                        </Tooltip>
                                        <input id="filterkda" type="range" className="slider" step={1} min={0} max={1000} value={filterSettings.kda * 100} onChange={(e) => (setfilterSettings({ ...filterSettings, kda: (parseInt(e.target.value) / 100) || 0 }))} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-slider">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>The minimum Gold/min a champ must have in a queue.</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtergold" >Gold &#062;= <b>{filterSettings.gold}</b></label>
                                        </Tooltip>
                                        <input id="filtergold" type="range" className="slider" min={0} max={600} value={filterSettings.gold} onChange={(e) => (setfilterSettings({ ...filterSettings, gold: parseInt(e.target.value) || 0 }))} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-slider">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>The minimum CS/min a champ must have in a queue.</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtercs" >CS &#062;= <b>{filterSettings.cs}</b></label>
                                        </Tooltip>
                                        <input id="filtercs" type="range" className="slider" min={0} max={2000} value={filterSettings.cs * 100} onChange={(e) => (setfilterSettings({ ...filterSettings, cs: (parseInt(e.target.value) / 100) || 0 }))} />
                                    </span>
                                </span>
                            </AccordionTab>
                            <AccordionTab header="Edit Queues">
                                <span className="tracker-content-filter-element">
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>select the queues you want to apply the filters on,<br /> or which ones you want to hide</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filternormalq" ><b>D</b>raft</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={filterSettings.normalQ} options={filterSettingsOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setfilterSettings({ ...filterSettings, normalQ: e.target.value })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>select the queues you want to apply the filters on,<br /> or which ones you want to hide</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtersoloq" ><b>S</b>olo</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={filterSettings.soloQ} options={filterSettingsOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setfilterSettings({ ...filterSettings, soloQ: e.target.value })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>select the queues you want to apply the filters on,<br /> or which ones you want to hide</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filterflexq" ><b>F</b>lex</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={filterSettings.flexQ} options={filterSettingsOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setfilterSettings({ ...filterSettings, flexQ: e.target.value })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>select the queues you want to apply the filters on,<br /> or which ones you want to hide</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filterclash" ><b>C</b>lash</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={filterSettings.clash} options={filterSettingsOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setfilterSettings({ ...filterSettings, clash: e.target.value })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>select the queues you want to apply the filters on,<br /> or which ones you want to hide</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtertournament" ><b>T</b>ournament</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={filterSettings.tournament} options={filterSettingsOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setfilterSettings({ ...filterSettings, tournament: e.target.value })} />
                                    </span>
                                </span>
                            </AccordionTab>
                            <AccordionTab header="Edit Stats">
                                <span className="tracker-content-filter-element">
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Hide All</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtergames" >Hide all</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={(!showStatsFilter.games &&
                                            !showStatsFilter.winrate &&
                                            !showStatsFilter.kda &&
                                            !showStatsFilter.kills &&
                                            !showStatsFilter.deaths &&
                                            !showStatsFilter.assists &&
                                            !showStatsFilter.gold &&
                                            !showStatsFilter.cs &&
                                            !showStatsFilter.visionScore)} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => {
                                                setShowStatsFilter({
                                                    ...showStatsFilter, games: false,
                                                    winrate: false,
                                                    kda: false,
                                                    kills: false,
                                                    deaths: false,
                                                    assists: false,
                                                    gold: false,
                                                    cs: false,
                                                    visionScore: false
                                                })
                                            }} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect games played</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtergames" >Games</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={showStatsFilter.games} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setShowStatsFilter({ ...showStatsFilter, games: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect Winrate</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filterwinrate" >Winrate</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={showStatsFilter.winrate} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setShowStatsFilter({ ...showStatsFilter, winrate: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect K/D/A</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filterkda" >K/D/A</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={showStatsFilter.kda} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setShowStatsFilter({ ...showStatsFilter, kda: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect kills</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filterkills" >Kills</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={showStatsFilter.kills} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setShowStatsFilter({ ...showStatsFilter, kills: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect deaths</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filterdeaths" >Deaths</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={showStatsFilter.deaths} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setShowStatsFilter({ ...showStatsFilter, deaths: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect assists</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filterassists" >Assists</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={showStatsFilter.assists} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setShowStatsFilter({ ...showStatsFilter, assists: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect Gold/min</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtergold" >Gold/min</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={showStatsFilter.gold} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setShowStatsFilter({ ...showStatsFilter, gold: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect CS/min</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtercs" >CS/min</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={showStatsFilter.cs} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setShowStatsFilter({ ...showStatsFilter, cs: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect vision Score</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtervisionScore" >vision Score</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={showStatsFilter.visionScore} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setShowStatsFilter({ ...showStatsFilter, visionScore: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                </span>
                            </AccordionTab>
                        </Accordion>
                    }

                    {
                        //Team-Comp analysis Filter
                    }
                    {endpoint === "getTeamcomp" &&
                        <Accordion multiple activeIndex={[0]}>
                            <AccordionTab header="Filter">
                                <span className="tracker-content-filter-element">
                                    <span className="tracker-content-filter-element-filter-slider">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>The minimum number of games a comp must have in a queue.</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtergames" >Games 	&#062;= <b>{compFilterSettings.games}</b></label>
                                        </Tooltip>
                                        <input id="filtergames" type="range" className="slider" min={2} max={20} value={compFilterSettings.games} onChange={(e) => (setCompFilterSettings({ ...compFilterSettings, games: parseInt(e.target.value) || 0 }))} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-slider">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>The minimum Winrate a champ must have in a queue.</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filterwinrate" >Winrate &#062;= <b>{compFilterSettings.winrate}</b></label>
                                        </Tooltip>
                                        <input id="filterwinrate" type="range" className="slider" min={0} max={100} value={compFilterSettings.winrate} onChange={(e) => (setCompFilterSettings({ ...compFilterSettings, winrate: parseInt(e.target.value) || 0 }))} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-slider">

                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>How much player need to be part of the comp.</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtercompsize" >Compsize &#062;= <b>{compFilterSettings.compSize}</b></label>
                                        </Tooltip>
                                        <input id="filtercompsize" type="range" className="slider" step={1} min={2} max={5} value={compFilterSettings.compSize} onChange={(e) => (setCompFilterSettings({ ...compFilterSettings, compSize: (parseInt(e.target.value)) || 2 }))} />
                                    </span>
                                </span>
                            </AccordionTab>
                            <AccordionTab header="Show Stats">
                                <span className="tracker-content-filter-element">
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect Games</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtershowGames" >Games</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={compFilterSettings.showGames} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setCompFilterSettings({ ...compFilterSettings, showGames: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect Names</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtershowWinrate" >Winrate</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={compFilterSettings.showWinrate} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setCompFilterSettings({ ...compFilterSettings, showWinrate: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect Game time</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtershowGametime" >Game time</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={compFilterSettings.showGameTime} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setCompFilterSettings({ ...compFilterSettings, showGameTime: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect Damage</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtershowDamage" >Damage</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={compFilterSettings.showDamage} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setCompFilterSettings({ ...compFilterSettings, showDamage: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                    <span className="tracker-content-filter-element-filter-checkbox">
                                        <Tooltip mouseEnterDelay={0.25} placement="right" overlay={<p>Select/Deselect Names</p>} >
                                            <label className="tracker-filter-input-label" htmlFor="filtershowNames" >Names</label>
                                        </Tooltip>
                                        <MultiStateCheckbox value={compFilterSettings.showNames} options={showStatsFilterOptions} optionValue="value" onChange={(e: MultiStateCheckboxChangeEvent) => setCompFilterSettings({ ...compFilterSettings, showNames: e.target.value === true || e.target.value === undefined || !(e.target.value === false) })} />
                                    </span>
                                </span>
                            </AccordionTab>
                            {!loading && resDataComp?.data?.champs &&
                                <AccordionTab header="Edit Champs">
                                    <span className="tracker-content-filter-element">
                                        <span className="tracker-content-filter-element-champs">
                                            {
                                                resDataComp?.data?.champs.map((champ: string) => {
                                                    return (
                                                        <ChampImg key={champ} name={champ} version={version} banList={compFilterSettings.compChamps} HandleBan={HandleCompChampSelector} isCompChampSelector={true} />
                                                    )
                                                })
                                            }
                                        </span>
                                    </span>
                                </AccordionTab>
                            }
                        </Accordion>

                    }
                </div>
                <div className="tracker-content-stats-wrapper">

                    <BanHighlighter names={banList} version={version} HandleBan={HandleBan} />
                    <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)} >
                        <TabPanel header="Player analysis">
                            <div className="tracker-content-stats">

                                {!loading && resData?.data?.player &&
                                    <>
                                        {resData.data.player.map((player: any) => {
                                            return (<PlayerStats key={player.name}
                                                rank={player.rank}
                                                roleStats={player.roleStats}
                                                champStats={player.champStats}
                                                name={player.name}
                                                elo={player.elo}
                                                roles={player.roles}
                                                version={version}
                                                summonerJson={summonerJson}
                                                runesJson={runesJson}
                                                itemJson={itemJson}
                                                filter={filterSettings}
                                                showStats={showStatsFilter}
                                                games={player.games}
                                                winrate={player.winrate}
                                                statsLoading={loadingStats}
                                                PlayerDetails={resDataStats.data}
                                                matchHistoryLoading={loadingMatchHistory}
                                                MatchHistoryData={resDataMatchHistory.data}
                                                HandleBan={HandleBan}
                                                BanList={banList}
                                                CopyLinkOptions={copyLinkOptions}
                                            />)
                                        })}
                                    </>
                                }
                            </div>
                        </TabPanel>
                        <TabPanel header="Team-Comp analysis" >
                            <div className="tracker-content-stats">
                                {!loading && resDataComp?.data?.comps &&
                                    <div className="Comps">
                                        {resDataComp.data.comps.map((comp: any) => {
                                            return (<CompCard key={comp.gameDuration}
                                                comp={comp.comp}
                                                games={comp.games}
                                                gameDuration={comp.gameDuration}
                                                totalDamage={comp.totalDamage}
                                                winrate={comp.winrate}
                                                version={version}
                                                filter={compFilterSettings}
                                                HandleBan={HandleBan}
                                                BanList={banList} />)
                                        })}
                                    </div>
                                }
                            </div>
                        </TabPanel>
                    </TabView>
                    {loading &&
                        <span className="tracker-content-box">
                            <LoadingIcon />
                        </span>
                    }
                    {requestError === "" && !loading && ((!resData.data && endpoint === "track") || (!resDataComp.data && endpoint === "getTeamcomp")) &&
                        <span className="tracker-content-box">
                            <span className="tracker-content-box-intro">
                                <span className="tracker-content-box-intro-bild">
                                    <img alt="" src={LogoPng} />
                                </span><br />
                                <span className="tracker-content-box-intro-text">
                                    <LogoText />
                                </span>
                                <br />
                                Rival Scout lets you track both single players or whole teams.
                                <br />
                                Rival Scout is specialized for a competetive analysis, but can also be used to look at yourself or your friends.
                                <br />
                                You may use filters to narrow down your results
                            </span>
                        </span>
                    }
                    {requestError !== "" &&
                        <span className="tracker-content-box">
                            <span className="tracker-content-box-intro">
                                <span className="tracker-content-box-intro-bild">
                                    <img alt="" src={LogoPng} />
                                </span><br />
                                <span className="tracker-content-box-intro-text">
                                    <LogoText />
                                </span>
                                <br />
                                <span className="ERROR">
                                    {requestError}
                                </span>
                            </span>
                        </span>
                    }
                </div>
            </div>
        </div >
    );
}

